export default {
  props: {
    aspectRatioClass: {
      type: String,
      default: null
    },
    objectPositionClass: {
      type: String,
      default: null
    },
    mobileSrc: {
      type: String,
      default () {
        return null
      }
    },
    desktopSrc: {
      type: String,
      default () {
        return null
      }
    },
    alt: {
      type: String,
      default () {
        return ''
      }
    },
    caption: {
      type: String,
      default () {
        return null
      }
    },
    mobileDisplaySize: {
      type: String,
      default () {
        return '100vw'
      }
    },
    desktopDisplaySize: {
      type: String,
      default () {
        return '100vw'
      }
    },
    lazyload: {
      type: Boolean,
      default () {
        return false
      }
    },
    imgLoading: {
      type: String,
      default: 'lazy'
    },
    imgSettings: {
      type: Object,
      default () {
        return {}
      }
    },
    imgSettingsDesktop: {
      type: Object,
      default () {
        return {}
      }
    }
  },
  data () {
    return {
      showing: !this.lazyload,
      pagePosition: 0 // position in px from top this image is rendered
    }
  },
  computed: {
    desktopSettings () {
      return { ...this.imgSettings, ...this.imgSettingsDesktop }
    },
    isSvg () {
      let fileformat
      if (this.mobileSrc) {
        fileformat = this.mobileSrc?.split('.').pop()
      } else {
        fileformat = this.desktopSrc?.split('.').pop()
      }
      return (fileformat === 'svg')
    },
    desktopSrcOut () {
      return this.desktopSrc || this.mobileSrc
    },
    /**
     * Check if the image processor supports web p
     * @return {boolean}
     */
    supportsWebP () {
      /**
       * Temporary fix since all images are jpg now,
       * @todo fix this when we have cloudfront support
       */
      return true
      /*
      if (/fastly.net\//.test(this.mobileSrc) || /.rackcdn.com\//.test(this.mobileSrc)) {
        return true
      }
      return false
       */
    },
    config () {
      // Keep the config flat, please
      const config = this.$config.imgProxy || {}
      return config
    }
  },
  mounted () {
    if (!this.showing) {
      this.setPagePosition()
      this.showing = this.checkInView()

      if (!this.showing) {
        document.addEventListener('scroll', this.scrolled, { passive: true })
      }
      window.addEventListener('resize', this.resize)
    }
  },
  beforeDestroy () {
    document.removeEventListener('scroll', this.scrolled)
    window.removeEventListener('resize', this.resize)
  },
  methods: {
    /**
     * Get all sizes need for <picture> srcset
     * @param {string} imgSrc
     * @param {object} settings
     * @param {string} device
     * @return {string}
     */
    getSrcset (imgSrc, settings, device = 'mobile') {
      const output = []
      let sizes = device === 'mobile'
        ? [1920, 4000] // mobile sizes
        : [1920, 4000] // desktop sizes
      // Remove upscaled image
      const dimensions = this.detectDimensions(imgSrc)
      if (dimensions) {
        sizes = sizes.filter(x => x <= dimensions.width)
      }
      for (const w in sizes) {
        const height = settings.ratio && settings.ratio > 0 ? Math.round(settings.width * settings.ratio) : undefined
        output.push(
          this.processCDNimage(imgSrc, { width: sizes[w], height, ...settings }) + ' ' + sizes[w] + 'w'
        )
      }

      return output.join(', ')
    },
    processCDNimage (url, attr) {
      return this.$imgproxy.transform(url, attr)
    },

    /**
     * Detects dimensions of original image
     * currently only works with storyblok
     * @param url
     * @return {null|{width: *, height: *}}
     */
    detectDimensions (url) {
      if (/a.storyblok.com\//.test(url)) {
        const dimensions = url.match(/\/(\d*)x(\d*)\//, 'g')
        // console.log('Match ' + dimensions);
        // eslint-disable-next-line
        // const dimensions = RegExp(/\/(\d*)x(\d*)\//, 'g').exec(url)
        if (dimensions) {
          return { width: dimensions[1], height: dimensions[2] }
        }
      }
      return null
    },

    // Check if image is in viewport
    checkInView () {
      // Scroll position + viewport height + margin
      return (window.scrollY + window.innerHeight + 150) >= this.pagePosition
    },
    resize () {
      this.setPagePosition()
      this.scrolled()
    },
    setPagePosition () {
      this.pagePosition = Math.round(window.scrollY + this.$refs.figure.getBoundingClientRect().top)
    },
    scrolled () {
      if (this.checkInView()) {
        this.showing = true
        document.removeEventListener('scroll', this.scrolled)
        window.removeEventListener('resize', this.resize)
      }
    },
    imageLoaded () {
      this.$emit('imageLoaded')
    }
  }

}
